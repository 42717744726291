import { Grid } from "@mui/material";
import { useStaticPicker } from "@mui/x-date-pickers/internals";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { profileSchema } from "components/schema";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateProfile } from "store/Slices/profileSlice";
import { getProfile } from "store/Slices/profileSlice";

const ProfileForm = ({ setprofilePayload, storePermissions }) => {
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    phone: "",
    address: "",
    representative_name: "",
    about_us: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm, setValues } =
    useFormik({
      initialValues: initialValues,
      validationSchema: profileSchema,
      onSubmit: (values) => {
        const payload = {
          representative_name: values.representative_name,
          about_us: values.about_us,
          address: values.address,
          name: values.name,
          phone: values.phone.toString(),
        };
        setprofilePayload(payload);
        submitPayload(payload);
      },
    });

  const handleDisabled = (val) => {
    if (storePermissions) {
      if (storePermissions.hasOwnProperty(val)) {
        return !storePermissions[val];
      }
      return null;
    }
  };
  const getProfileData = () => {
    dispatch(
      getProfile({
        onSuccess: (profileData) => {
          setValues({
            name: profileData?.name || "",
            phone: profileData?.phone || "",
            address: profileData?.address || "",
            representative_name: profileData?.representative_name || "",
            about_us: profileData?.about_us || "",
          });
        },
      })
    )
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  const submitPayload = (payload) => {
    dispatch(
      updateProfile({
        payload,
        onSuccess: () => {
          getProfileData();
        },
      })
    )
      .then((res) => {})
      .catch((rejected) => toast.error(rejected?.response?.data?.detail));
  };

  useEffect(() => {
    getProfileData();
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12} md={6}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              {errors.name && touched.name ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.name}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Phone Number"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              {errors.phone && touched.phone ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.phone}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox mb={2}>
              <MDInput
                label="Add your Address"
                name="address"
                multiline
                rows={1}
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              {errors.address && touched.address ? (
                <MDTypography color="error" sx={{ fontSize: "13px" }}>
                  {<p>{errors.address}</p>}
                </MDTypography>
              ) : null}
            </MDBox>
          </Grid>
        </Grid>

        <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            disabled={handleDisabled("update")}
          >
            Save Settings
          </MDButton>
        </MDBox>
      </form>
    </>
  );
};

export default ProfileForm;
