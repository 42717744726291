import { Box, TextField, TextareaAutosize } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useRef, useState } from "react";
import Select from "react-select";
import DropDowns from "./DropDowns";
import { useDispatch, useSelector } from "react-redux";
import { importQuestion } from "store/Slices/questionsSlice";
import { getQuestions } from "store/Slices/questionsSlice";
import TextFields from "./TextFields";
const bodyStyle = {
  height: "auto",
};
export default function ImportQuestions({
  importQuestions,
  setImportQuestions,
  rolesDetails,
  categoriesDetails,
  setAddModalOpen,
  debouncedSearch,
  questionsType,
  page,
}) {
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const { loading } = useSelector((state) => state.questions);
  const [closeModal, setCloseModal] = useState();
  const handleQuestionFileChange = (event) => {
    const updatedQuestions = { ...importQuestions, file: event.target.files[0] };
    setImportQuestions(updatedQuestions);
  };

  const handleTextFields = (index, event) => {
    const { name, value } = event.target;
    setImportQuestions({ ...importQuestions, [name]: value });
  };

  const handleLocationChange = (name, index, value) => {
    const updatedQuestions = { ...importQuestions, [name]: value };
    setImportQuestions(updatedQuestions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", importQuestions.file);
    const fields = [
      "role_category_id",
      "role_subcategory_id",
      "question_type_id",
      "specific_role",
      "location",
      "company_name",
    ];

    fields.forEach((field) => {
      if (importQuestions?.[field]) {
        formData.append(field, importQuestions[field]);
      }
    });
    dispatch(
      importQuestion({
        payload: formData,
        onSuccess: () => {
          if (closeModal) {
            setImportQuestions({
              question_type_id: "",
              role_category_id: "",
              role_subcategory_id: null,
              file: "",
              roleId: "",
              specific_role: "",
              location: "",
              company_name: "",
            });
          } else {
            setImportQuestions({
              ...importQuestions,
              file: "",
            });
            fileRef.current.value = "";
          }
          dispatch(getQuestions({ page: page, search: debouncedSearch }));
        },
      })
    );
    if (closeModal) {
      setAddModalOpen(false);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={bodyStyle}>
        <Box mb={2}>
          <DropDowns
            rolesDetails={rolesDetails}
            question={importQuestions}
            categoriesDetails={categoriesDetails}
            Questions={importQuestions}
            setQuestions={setImportQuestions}
            index={""}
            questionsType={questionsType}
            isDisabled={loading}
          />
          <TextFields
            question={importQuestions}
            index={""}
            handleTextFields={handleTextFields}
            handleLocationChange={handleLocationChange}
            isDisabled={loading}
          />
          <input
            type="file"
            accept=".csv, .xlsx"
            ref={fileRef}
            onChange={(event) => {
              if (event.target.files.length > 0) {
                handleQuestionFileChange(event);
              }
            }}
            style={{ width: "100%", marginTop: "10px", padding: "10px" }}
            required
          />
        </Box>
      </Box>
      <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => setAddModalOpen(false)}
          disabled={loading}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          type="submit"
          disabled={loading}
          onClick={() => setCloseModal(true)}
        >
          Submit and Close
        </MDButton>
        <MDButton variant="gradient" color="info" type="submit" disabled={loading}>
          Submit and Continue
        </MDButton>
      </MDBox>
    </form>
  );
}
