import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const dashRepo = RepositoryFactory.get("Dashboard");

export const createUsers = createAsyncThunk(
  "users/createUsers",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.addUsers(payload);
      return data;
      // if (data.detail == "Invitations sent successfully") {
      //   toast.success(data.detail);
      //   onSuccess();
      //   return data;
      // } else {
      //   toast.error(data.detail);
      // }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getAllUsers(payload);
      if (data.status_code === 200) {
        onSuccess();
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getJoinedUsers = createAsyncThunk(
  "users/getJoinedUsers",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getAllJoinedUsers();
      if (data) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const revokeInvitaion = createAsyncThunk(
  "users/revokeInvitaion",
  async ({ email, status, onSuccess }, { rejectWithValue }) => {
    try {
      let payload = {
        email,
        status,
      };
      const { data } = await dashRepo.revokeInvitaion(payload);
      onSuccess(data?.message);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const refreshInvitation = createAsyncThunk(
  "users/refreshInvitation",
  async ({ email, onSuccess }, { rejectWithValue }) => {
    try {
      let payload = {
        email,
      };
      const { data } = await dashRepo.refreshInvitation(payload);
      // console.log(data, "databyahsan");
      onSuccess(data?.detail);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteInvitation = createAsyncThunk(
  "users/deleteInvitation",
  async ({ email, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.deleteInvitaion(email);
      if (data.status_code === 200) {
        onSuccess(data?.detail);
        return data?.detail;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getStats = createAsyncThunk(
  "users/getStats",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.GET_Stats();
      if (data.status_code === 200) {
        onSuccess(data?.data);
        return data?.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserTrainingStats = createAsyncThunk(
  "profile/getUserDetailStats",
  async (ID, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getUserDetailStats(ID);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const userResumeStats = createAsyncThunk(
  "profile/userResumeStats",
  async (id, { rejectWithValue }) => {
    let payload = {
      customer_id: id,
    };
    try {
      const { data } = await dashRepo.getUserResumeStats(payload);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getUserCompletedResumes = createAsyncThunk(
  "profile/completedResumeStats",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getCompletedResumeStats(id);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getUserAvgScore = createAsyncThunk(
  "profile/avgScore",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.userAvgScore(id);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getUserFeedbackSatisfaction = createAsyncThunk(
  "profile/feedbackSatisfaction",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.userFeedbackSatisfaction(id);
      if (data.status_code == 200) {
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getUserTrainingStatus = createAsyncThunk(
  "profile/trainingStatus",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.trainingStatus(id);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getUserSimultionStatus = createAsyncThunk(
  "profile/simultionsStatus",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.simulationStatus(id);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserSimulationStats = createAsyncThunk(
  "profile/InterviewsimultionsStatus",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.interviewsimulationStatus(id);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserResume = createAsyncThunk(
  "profile/getUserResume",
  async ({ spiltID }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getUserResume(spiltID);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getUserPRounds = createAsyncThunk(
  "profile/getUserPRounds",
  async ({ spiltID, page }, { rejectWithValue }) => {
    try {
      let payload = {
        customer_id: spiltID,
        page: page,
      };
      const { data } = await dashRepo.UserPRounds(payload);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getPRoundFeedback = createAsyncThunk(
  "profile/getPRoundFeedback",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.pRoundFeedback(payload);
      if (data.status_code == 200) {
        let response = {
          feedbackList: data.feedbackList,
          score: data.averageScore,
        };
        return response;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserSimulations = createAsyncThunk(
  "profile/getUserSimulations",
  async ({ spiltID, page }, { rejectWithValue }) => {
    try {
      let payload = {
        customer_id: spiltID,
        page: page,
      };
      const { data } = await dashRepo.UserSimulations(payload);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSimulationFeedback = createAsyncThunk(
  "profile/getSimulationFeedback",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.simulationFeedback(payload);
      if (data.status_code == 200) {
        let response = {
          feedbackList: data.feedbackList,
          score: data.averageScore,
        };
        return response;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSingleResume = createAsyncThunk(
  "profile/getSingleResume",
  async ({ id, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getSingleResume(id);
      if (data.status_code == 200) {
        onSuccess();
        return data.data;
      }
    } catch (err) {
      onError(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);
export const getSkillGapAnalysis = createAsyncThunk(
  "profile/getSkillGapAnalysis",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getSkillGapAnalysis(id);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      onError(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const getUserOnBoarding = createAsyncThunk(
  "profile/getUserOnBoarding",
  async ({ id }, { rejectWithValue }) => {
    try {
      const data = await dashRepo.getUserOnBoarding(id);
      if (data.status == 200) {
        return data.data.entries;
      }
    } catch (err) {
      onError(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const getUserLinkedinProfile = createAsyncThunk(
  "profile/getUserLinkedinProfile",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getUserLinkedinProfile(id);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      onError(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);
export const getUserInfoById = createAsyncThunk(
  "profile/getUserInfoById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getUserInfoById(id);
      return data.data;
    } catch (err) {
      onError(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const updateUserInfoById = createAsyncThunk(
  "profile/updateUserInfoById",
  async ({ id, payload, onSuccess }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await dashRepo.updateUserInfoById({ id, payload });
      await dispatch(getUserInfoById(id));
      onSuccess();
      return data.data;
    } catch (err) {
      onError(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const setUserLinkedinProfile = createAsyncThunk(
  "profile/setUserLinkedinProfile",
  async ({ id, payload, onSuccess }, { rejectWithValue }) => {
    try {
      const data = await dashRepo.setUserLinkedinProfile(id, payload);
      if (data.status == 200) {
        onSuccess();
        return data.data.entries;
      }
    } catch (err) {
      onError(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const getUserEmploymentStatus = createAsyncThunk(
  "profile/getUserEmploymentStatus",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getUserEmploymentStatus(id);
      if (data.status_code == 200) {
        return data.data;
      }
    } catch (err) {
      onError(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const setUserEmploymentStatus = createAsyncThunk(
  "profile/setUserEmploymentStatus",
  async ({ id, payload, onSuccess }, { rejectWithValue }) => {
    try {
      const data = await dashRepo.setUserEmploymentStatus(id, payload);
      if (data.status == 200) {
        onSuccess();
        return data.data.entries;
      }
    } catch (err) {
      onError(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const getContextViewRedirectLink = createAsyncThunk(
  "profile/getContextViewRedirectLink",
  async (id, { rejectWithValue }) => {
    try {
      const response = await dashRepo.getContextView(id);
      if (response.data?.status_code == 200) {
        toast.success("Thank you for your interest. We will get back to you shortly.");
        setTimeout(() => {
          window.open(response.data?.data?.redirect_link, "_blank");
        }, 1500);
        return response.data?.data?.redirect_link;
      }
    } catch (err) {
      onError(err?.response?.data?.detail);
      return rejectWithValue(err);
    }
  }
);

export const getRemoveUsersLimit = createAsyncThunk(
  "users/getRemoveUsersLimit",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.getRemoveUsersLimit();
      onSuccess(data?.data?.removable_quantity);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteSeats = createAsyncThunk(
  "users/deleteSeats",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.deleteSeats(payload);
      onSuccess();
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);

export const monthlyAvgScore = createAsyncThunk(
  "users/monthlyAvgScore",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.monthlyAvgScore(payload);
      return data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
export const careerReadiness = createAsyncThunk(
  "users/careerReadiness",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.careerReadiness(payload);
      return data.data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
export const summaryReport = createAsyncThunk(
  "users/summaryReport",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.summaryReport(payload);
      return data.data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
export const loginFrequency = createAsyncThunk(
  "users/loginFrequency",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.loginFrequency(payload);
      return data.data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
export const timeSpent = createAsyncThunk(
  "users/timeSpent",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.timeSpent(payload);
      return data.data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
export const simulationScorePerUser = createAsyncThunk(
  "users/simulationScorePerUser",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.simulationScorePerUser(payload);
      return data.data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
export const pRoundScorePerUser = createAsyncThunk(
  "users/pRoundScorePerUser",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.pRoundScorePerUser(payload);
      return data.data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
export const simulationSessionTopScore = createAsyncThunk(
  "users/simulationSessionTopScore",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.simulationSessionTopScore(payload);
      return data.data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
export const pRoundSessionTopScore = createAsyncThunk(
  "users/pRoundSessionTopScore",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.pRoundSessionTopScore(payload);
      return data.data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
export const incompletionRates = createAsyncThunk(
  "users/incompletionRates",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.incompletionRates(payload);
      return data.data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);

///// Iq Search count Action
export const IqSearchCount = createAsyncThunk(
  "users/IqSearchCount",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.IqSearchCount(payload);
      return data.data.count;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
///// Iq Search count Action
export const IqAlertCount = createAsyncThunk(
  "users/IqAlertCount",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.IqAlertCount(payload);
      return data.data.count;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
///// Iq Applied Job count Action
export const IqAppliedJobCount = createAsyncThunk(
  "users/IqAppliedJobCount",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dashRepo.IqAppliedJobCount(payload);
      return data.data;
    } catch (err) {
      toast.error("SomeThing Went Wrong");
      return rejectWithValue(err);
    }
  }
);
export const getResumeFeedback = createAsyncThunk(
  "users/getResumeFeedback",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const data = await dashRepo.getResumeFeedback(id);
      if (data) {
        onSuccess(data);
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getProfessions = createAsyncThunk(
  "user/getProfessions",
  async ({ page, onComplete = () => {} }, { rejectWithValue }) => {
    try {
      const response = await dashRepo.getProfessions(page);
      const data = response.data.data;
      onComplete();
      return data;
    } catch (err) {
      onComplete();
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  users: null,
  loading: false,
  error: null,
  userTrainingStats: null,
  userSimulationStats: null,
  userResumes: null,
  resumeAvgScore: null,
  total_invitations: null,
  userResume: [],
  singleResume: null,
  skillGapData: null,
  userOnBoardingData: [],
  linkedinUrlLink: null,
  emplymentStatus: null,
  contextCopying: false,
  joinedUsers: [],
  trainingStatus: false,
  roundsCompletionData: null,
  simulationData: null,
  simulationStatus: false,
  satisfaction: "",
  satisfactionDetail: "",
  monthlyAvgScoreVal: "",
  monthlyAvgScoreDetail: "",
  careerReadinessVal: "",
  summaryReportVal: "",
  loginFrequencyVal: "",
  timeSpentVal: "",
  simulationScorePerUserVal: "",
  pRoundScorePerUserVal: "",
  simulationSessionTopScoreVal: "",
  pRoundSessionTopScoreVal: "",
  incompletionRatesVal: "",
  userInfo: null,
  professions: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getProfessions.fulfilled, (state, action) => {
        if (action.meta.arg.page === 1) {
          state.professions = action.payload;
        } else {
          state.professions = {
            ...action.payload,
            professions: [...state.professions.professions, ...action.payload.professions],
          };
        }
      })
      .addCase(getResumeFeedback.pending, (state) => {
        state.loading = true;
      })
      .addCase(getResumeFeedback.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getResumeFeedback.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUsers.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSeats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSeats.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteSeats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.invitations;
        state.total_invitations = action.payload.total_invitations;
        let pagination = {
          current_page: action.payload?.current_page,
          limit: action.payload?.limit,
          total_pages: action.payload?.total_pages,
          total_invitations: action.payload?.total_invitations,
        };
        state.paginationData = pagination;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getJoinedUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJoinedUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.joinedUsers = action.payload;
      })
      .addCase(getJoinedUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteInvitation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteInvitation.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteInvitation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(revokeInvitaion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(revokeInvitaion.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(revokeInvitaion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(refreshInvitation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refreshInvitation.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(refreshInvitation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserTrainingStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserTrainingStats.fulfilled, (state, action) => {
        state.loading = false;
        state.userTrainingStats = action.payload;
      })
      .addCase(getUserTrainingStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserSimulationStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserSimulationStats.fulfilled, (state, action) => {
        state.loading = false;
        state.userSimulationStats = action.payload;
      })
      .addCase(getUserSimulationStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userResumeStats.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.resumeAvgScore = null;
        state.userResumes = null;
      })
      .addCase(userResumeStats.fulfilled, (state, action) => {
        state.loading = false;
        state.userResumes = action.payload?.total_resume;
        state.resumeAvgScore = action.payload?.average_score;
      })
      .addCase(userResumeStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserCompletedResumes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserCompletedResumes.fulfilled, (state, action) => {
        state.loading = false;
        state.compltedResumeCount = action.payload?.completed_resumes_count;
      })
      .addCase(getUserCompletedResumes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserAvgScore.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserAvgScore.fulfilled, (state, action) => {
        state.loading = false;
        state.averageScore = action.payload?.average_score;
      })
      .addCase(getUserAvgScore.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserFeedbackSatisfaction.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserFeedbackSatisfaction.fulfilled, (state, action) => {
        state.loading = false;
        state.satisfaction = action.payload?.data?.satisfaction_level;
        state.satisfactionDetail = action?.payload?.detail;
      })
      .addCase(getUserFeedbackSatisfaction.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserTrainingStatus.pending, (state) => {
        // console.log("Running");
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserTrainingStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.trainingStatus = action.payload?.has_completed_sessions;
        state.roundsCompletionData = action.payload;
      })
      .addCase(getUserTrainingStatus.rejected, (state, action) => {
        // console.log("Error");
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserSimultionStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserSimultionStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.simulationStatus = action.payload?.has_completed_sessions;
        state.simulationData = action.payload;
      })
      .addCase(getUserSimultionStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserResume.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserResume.fulfilled, (state, action) => {
        state.loading = false;
        state.userResume = action.payload;
      })
      .addCase(getUserResume.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSingleResume.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleResume.fulfilled, (state, action) => {
        state.loading = false;
        state.singleResume = action.payload;
      })
      .addCase(getSingleResume.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSkillGapAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSkillGapAnalysis.fulfilled, (state, action) => {
        state.loading = false;
        state.skillGapData = action.payload;
      })
      .addCase(getSkillGapAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserOnBoarding.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserOnBoarding.fulfilled, (state, action) => {
        state.loading = false;
        state.userOnBoardingData = action.payload;
      })
      .addCase(getUserOnBoarding.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserLinkedinProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserLinkedinProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.linkedinUrlLink = action.payload;
      })
      .addCase(getUserLinkedinProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(setUserLinkedinProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setUserLinkedinProfile.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setUserLinkedinProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserEmploymentStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserEmploymentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.emplymentStatus = action.payload;
      })
      .addCase(getUserEmploymentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(setUserEmploymentStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setUserEmploymentStatus.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setUserEmploymentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserPRounds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserPRounds.fulfilled, (state, action) => {
        state.loading = false;
        state.practiceRoundsList = action.payload.sessions;
        let pagination = {
          current_page: action.payload?.current_page,
          limit: action.payload?.limit,
          total_pages: action.payload?.total_pages,
          total_sessions: action.payload?.total_sessions,
        };
        state.pRoundPagination = pagination;
      })
      .addCase(getUserPRounds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserSimulations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserSimulations.fulfilled, (state, action) => {
        state.loading = false;
        state.simulationsList = action.payload.sessions;
        let pagination = {
          current_page: action.payload?.current_page,
          limit: action.payload?.limit,
          total_pages: action.payload?.total_pages,
          total_sessions: action.payload?.total_sessions,
        };
        state.simulationsPagination = pagination;
      })
      .addCase(getUserSimulations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getContextViewRedirectLink.pending, (state) => {
        state.contextCopying = true;
        state.error = null;
      })
      .addCase(getContextViewRedirectLink.fulfilled, (state, action) => {
        state.contextCopying = false;
      })
      .addCase(getContextViewRedirectLink.rejected, (state, action) => {
        state.contextCopying = false;
        state.error = action.error.message;
      })
      .addCase(monthlyAvgScore.pending, (state) => {
        state.loading = true;
        state.monthlyAvgScoreVal = "";
      })
      .addCase(monthlyAvgScore.fulfilled, (state, action) => {
        state.loading = false;
        state.monthlyAvgScoreVal = action.payload.data.monthly_average_scores;
        state.monthlyAvgScoreDetail = action.payload.detail;
      })
      .addCase(monthlyAvgScore.rejected, (state) => {
        state.loading = false;
      })
      .addCase(careerReadiness.pending, (state) => {
        state.loading = true;
        state.careerReadinessVal = "";
      })
      .addCase(careerReadiness.fulfilled, (state, action) => {
        state.loading = false;
        state.careerReadinessVal = action.payload.career_readiness;
      })
      .addCase(careerReadiness.rejected, (state) => {
        state.loading = false;
      })
      .addCase(summaryReport.pending, (state) => {
        state.loading = true;
        state.summaryReportVal = "";
      })
      .addCase(summaryReport.fulfilled, (state, action) => {
        state.loading = false;
        state.summaryReportVal = action.payload;
      })
      .addCase(summaryReport.rejected, (state) => {
        state.loading = false;
      })
      .addCase(loginFrequency.pending, (state) => {
        state.loading = true;
        state.loginFrequencyVal = "";
      })
      .addCase(loginFrequency.fulfilled, (state, action) => {
        state.loading = false;
        state.loginFrequencyVal = action.payload.last_seven_days_login_frequency;
      })
      .addCase(loginFrequency.rejected, (state) => {
        state.loading = false;
      })
      .addCase(timeSpent.pending, (state) => {
        state.loading = true;
        state.timeSpentVal = "";
      })
      .addCase(timeSpent.fulfilled, (state, action) => {
        state.loading = false;
        state.timeSpentVal = action.payload.time_spent_in_minutes;
      })
      .addCase(timeSpent.rejected, (state) => {
        state.loading = false;
      })
      .addCase(simulationScorePerUser.pending, (state) => {
        state.loading = true;
        state.simulationScorePerUserVal = "";
      })
      .addCase(simulationScorePerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.simulationScorePerUserVal = action.payload.average_score;
      })
      .addCase(simulationScorePerUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(pRoundScorePerUser.pending, (state) => {
        state.loading = true;
        state.pRoundScorePerUserVal = "";
      })
      .addCase(pRoundScorePerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.pRoundScorePerUserVal = action.payload.average_score;
      })
      .addCase(pRoundScorePerUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(simulationSessionTopScore.pending, (state) => {
        state.loading = true;
        state.simulationSessionTopScoreVal = "";
      })
      .addCase(simulationSessionTopScore.fulfilled, (state, action) => {
        state.loading = false;
        state.simulationSessionTopScoreVal = action.payload?.score || 0;
      })
      .addCase(simulationSessionTopScore.rejected, (state) => {
        state.loading = false;
      })
      .addCase(pRoundSessionTopScore.pending, (state) => {
        state.loading = true;
        state.pRoundSessionTopScoreVal = "";
      })
      .addCase(pRoundSessionTopScore.fulfilled, (state, action) => {
        state.loading = false;
        state.pRoundSessionTopScoreVal = action.payload?.score || 0;
      })
      .addCase(pRoundSessionTopScore.rejected, (state) => {
        state.loading = false;
      })
      .addCase(incompletionRates.pending, (state) => {
        state.loading = true;
        state.incompletionRatesVal = "";
      })
      .addCase(incompletionRates.fulfilled, (state, action) => {
        state.loading = false;
        state.incompletionRatesVal = action.payload;
      })
      .addCase(incompletionRates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(IqSearchCount.pending, (state) => {
        state.loading = true;
        state.iqSearchCountVal = "";
      })
      .addCase(IqSearchCount.fulfilled, (state, action) => {
        state.loading = false;
        state.iqSearchCountVal = action.payload;
      })
      .addCase(IqSearchCount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(IqAlertCount.pending, (state) => {
        state.loading = true;
        state.iqSearchCountVal = "";
      })
      .addCase(IqAlertCount.fulfilled, (state, action) => {
        state.loading = false;
        state.iqAlertCountVal = action.payload;
      })
      .addCase(IqAlertCount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(IqAppliedJobCount.pending, (state) => {
        state.loading = true;
        state.iqSearchCountVal = "";
      })
      .addCase(IqAppliedJobCount.fulfilled, (state, action) => {
        state.loading = false;
        state.iqAppliedCountVal = action.payload;
      })
      .addCase(IqAppliedJobCount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUserInfoById.pending, (state) => {
        state.userInfo = null;
        state.loading = true;
      })
      .addCase(getUserInfoById.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
      })
      .addCase(getUserInfoById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateUserInfoById.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserInfoById.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateUserInfoById.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default usersSlice.reducer;
