import { WidthFull } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Loader from "components/Loader";

import MDBox from "components/MDBox";
import { permission } from "context";
import { getRouteByNumber } from "context";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSubscription } from "store/Slices/authSlice";
import { todayActiveCustomer } from "store/Slices/profileSlice";
import { avgPRoundScore } from "store/Slices/profileSlice";
import { orgIncompletionRates } from "store/Slices/profileSlice";
import { resumeCompletionRate } from "store/Slices/profileSlice";
import { avgInterviewSim } from "store/Slices/profileSlice";
import { getDashboardSimulationStats } from "store/Slices/profileSlice";
import { totalUserCount } from "store/Slices/profileSlice";
import { getOrgResumeStats } from "store/Slices/profileSlice";
import { getDashboardTrainingStats } from "store/Slices/profileSlice";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mainLoader, setMainLoader] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const {
    dashboardTrainingStats,
    dashboardSimulationStats,
    orgResume,
    dashboardLoaders,
    totalUserCountVal,
    todayActiveCustomerVal,
    avgInterviewSimVal,
    avgPRoundScoreVal,
    orgIncompletionRatesVal,
    resumeCompletionRateVal,
  } = useSelector((state) => state.orgProfile);
  const parsedObject = JSON.parse(user?.rolePermissions);
  let pagePermissions;
  let featurePermissions;
  if (parsedObject) {
    const [key, value] = Object.entries(parsedObject)[0];
    pagePermissions = key;
    featurePermissions = value;
  }

  const CardsLoader = () => (
    <MDBox
      sx={{
        display: "flex",
        justifyContent: "end",
        width: "100%",
        pr: "20px",
        pt: "20px",
      }}
    >
      <div className="dotsLoader" />
    </MDBox>
  );

  useEffect(() => {
    if (pagePermissions !== permission.admin && pagePermissions !== permission.dashboard) {
      const routeLink = getRouteByNumber(pagePermissions);
      return navigate(routeLink);
    }
    dispatch(getDashboardTrainingStats());
    dispatch(getDashboardSimulationStats());
    dispatch(getOrgResumeStats());
    dispatch(getSubscription());

    dispatch(totalUserCount());
    dispatch(todayActiveCustomer());
    dispatch(avgInterviewSim());
    dispatch(avgPRoundScore());
    dispatch(orgIncompletionRates());
    dispatch(resumeCompletionRate());
  }, []);

  useEffect(() => {
    setMainLoader(
      Object.values(dashboardLoaders).some((loader) => loader === false) ? false : true
    );
  }, [dashboardLoaders]);

  return (
    <DashboardLayout>
      {mainLoader && <Loader />}
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Practice Rounds"
                count={
                  !dashboardLoaders.trainingStats ? (
                    dashboardTrainingStats?.total_sessions
                  ) : (
                    <CardsLoader />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Interviews Taken"
                count={
                  !dashboardLoaders.simulationStats ? (
                    dashboardSimulationStats?.total_sessions
                  ) : (
                    <CardsLoader />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Resume's Started"
                count={!dashboardLoaders.resumeStats ? orgResume : <CardsLoader />}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="money"
                title="Money Saved"
                count={
                  !dashboardLoaders.resumeStats ? (
                    `$${((Number(dashboardTrainingStats?.total_sessions || 0) * 30) / 60) * 50}`
                  ) : (
                    <CardsLoader />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="save"
                title="Time Saved"
                count={
                  !dashboardLoaders.trainingStats ? (
                    `${Number(dashboardTrainingStats?.total_sessions || 0) * 30} minutes`
                  ) : (
                    <CardsLoader />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="people"
                title="Total Users"
                count={!dashboardLoaders.totalUserCount ? totalUserCountVal : <CardsLoader />}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="notifications"
                title="Today Active Users"
                count={
                  !dashboardLoaders.todayActiveCustomer ? (
                    todayActiveCustomerVal?.total_active_users
                  ) : (
                    <CardsLoader />
                  )
                }
                userList={todayActiveCustomerVal?.todays_active_users || []}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="secondary"
                icon="scoreboard"
                title="Average Interview Simulation Score"
                count={
                  !dashboardLoaders.avgInterviewSim ? (
                    Number(avgInterviewSimVal).toFixed(2)
                  ) : (
                    <CardsLoader />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="scoreboard"
                title="Average Practice Round Score"
                count={
                  !dashboardLoaders.avgPRoundScore ? (
                    Number(avgPRoundScoreVal).toFixed(2)
                  ) : (
                    <CardsLoader />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="pendingActions"
                title="Incomplete Interview Simulation"
                count={
                  !dashboardLoaders.orgIncompletionRates ? (
                    `${orgIncompletionRatesVal?.incomplete_simulations || 0}/${
                      orgIncompletionRatesVal?.total_simulations || 0
                    }`
                  ) : (
                    <CardsLoader />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="pendingActions"
                title="Incomplete Practice Rounds"
                count={
                  !dashboardLoaders.orgIncompletionRates ? (
                    `${orgIncompletionRatesVal?.incomplete_trainings || 0}/${
                      orgIncompletionRatesVal?.total_trainings || 0
                    }`
                  ) : (
                    <CardsLoader />
                  )
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="checklist"
                title="Resume Completion Rate"
                count={
                  !dashboardLoaders.resumeCompletionRate ? (
                    `${resumeCompletionRateVal?.completed_resumes_count || 0}/${
                      resumeCompletionRateVal?.total_resumes_count || 0
                    }`
                  ) : (
                    <CardsLoader />
                  )
                }
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
